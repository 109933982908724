import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "@app/auth/auth.service";
import { ExamService } from "@app/exambundle/exam.service";
import { UtilService } from "@app/shared/services/util.service";

import { IExamAttempt } from "@app/exambundle/models/IExamAttempt";
import { Exam } from "@app/exambundle/models/Exam";
import { AssignmentType } from "@app/shared/enums/assignment-type-enum";
import { ExamStatus } from "@app/shared/enums/exam-status-enum";
import { TrainingLevelEnum } from "@app/shared/enums/TrainingLevelEnum";

import { Assignment } from "@app/shared/models/entities/course/Assignment";
import { HttpErrorResponse } from "@angular/common/http";
import { LogService } from "@app/shared/services/log.service";
import { Place } from "@app/shared/enums/place-enum";

@Component({
  selector: "training-exam-qr-landing.component",
  templateUrl: "./exam-qr-landing.component.html",
  styleUrls: ["./exam-qr-landing.component.scss"],
})
export class ExamQRLandingComponent implements OnInit {
  protected TrainingLevelEnum: typeof TrainingLevelEnum = TrainingLevelEnum;

  protected oldExamId!: number;
  protected oldExam!: Exam;
  protected examAttempt!: IExamAttempt;

  protected isAuthenticated!: boolean;
  protected isLoading!: boolean;
  protected title!: string;
  protected error!: HttpErrorResponse;

  protected DummyAnswerId: number = 435754;

  constructor(
    private examService: ExamService,
    private utilService: UtilService,
    private logService: LogService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getParams();
    this.createAnonymousLog();
    if (!this.authService.isAuthenticated()) {
      this.isAuthenticated = false;
    } else {
      this.isAuthenticated = true;
      this.getOldExam();
    }
  }

  private getParams() {
    this.oldExamId = +this.route.snapshot.params["old-exam-id"];
  }

  private createAnonymousLog() {
    this.logService.startAnonymousLog(Place.ExamQRLandingPage, "ExamQRLandingPage", "OldExam", this.oldExamId);
  }

  private getOldExam() {
    this.isLoading = true;
    this.examService.getOldExam(this.oldExamId).subscribe({
      next: (oldExam) => {
        this.oldExam = oldExam;
        this.title = UtilService.getExamTitle(this.oldExam);
        this.utilService.setTitle(this.title);
        this.createExamAttempt();
      },
      error: (error) => {
        this.isLoading = false;
        this.error = error;
      },
    });
  }

  private createExamAttempt() {
    this.examService
      .createExamAttempt({
        old_exam: this.oldExam.id,
        training: this.oldExam.training as number,
        status: ExamStatus.ToBeFullyReviewed,
      })
      .subscribe({
        next: (examAttempt) => {
          this.examAttempt = examAttempt;
          this.createDummyAnswers();
        },
      });
  }

  private createDummyAnswers() {
    this.examAttempt.exam_attempt_assignments?.forEach((examAssignment) => {
      this.examService
        .createUserAnswer(this.examAttempt.id, examAssignment.assignment.id, {
          score: undefined,
          answer: this.getAnswer(examAssignment.assignment),
        })
        .subscribe();
    });
    this.isLoading = false;
  }

  private getAnswer(assignment: Assignment) {
    const dummyAnswersArray = new Array(assignment.answers?.length).fill(this.DummyAnswerId);
    switch (assignment.assignment_type) {
      case AssignmentType.Open:
        return "Vraag gemaakt in examenboekje.";
      case AssignmentType.MultipleChoice:
        return this.DummyAnswerId;
      case AssignmentType.FillIn:
        return new Array(assignment.answers?.length).fill("");
      case AssignmentType.CorrectOrder:
        return this.getStringAnswerIds(dummyAnswersArray);
      case AssignmentType.MultipleCorrect:
        return this.getStringAnswerIds(dummyAnswersArray);
    }
  }

  private getStringAnswerIds(answers: number[]): string {
    let answerIds = "";
    answers.forEach((answerId, i) => {
      answerIds += answerId + (i < answers.length - 1 ? "," : "");
    });
    return answerIds;
  }

  protected onLoginClicked() {
    this.router.navigate(["auth"], {
      queryParams: { next: this.router.url, "auth-mode": "login" },
    });
  }

  protected onRegisterClicked() {
    this.router.navigate(["auth"], {
      queryParams: { next: this.router.url, "auth-mode": "signup" },
    });
  }

  protected onContinueClicked() {
    this.isLoading = true;
    this.router.navigate(["vak", this.examAttempt.training?.id, "examens", this.examAttempt.id, "review"]);
  }
}
