import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Subject } from "rxjs";

import { UserExtended } from "@app/shared/models/entities/user/UserExtended";

import { SchoolService } from "../../school.service";
import { SchoolPublicData } from "@app/school/models/SchoolPublicData";
import { UtilService } from "@app/shared/services/util.service";

@Component({
  selector: "school-franchisers",
  templateUrl: "./franchisers.component.html",
  styleUrls: ["./franchisers.component.scss"],
})
export class FranchisersComponent implements OnInit, OnDestroy {
  authorizedRegistratorForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  studentForms: FormGroup[] = [];

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;
  isAuthorized!: boolean;
  authorizedRegistersErrorMessage!: string;

  userExtended!: UserExtended;
  school!: SchoolPublicData;

  useStoreNrEverywhere!: boolean;
  firstStoreNr!: string;

  registratorEmail!: string;
  students: any[] = [];

  constructor(
    private schoolService: SchoolService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    if (UtilService.isSchool24()) {
      window.open("https://portal.onlineslagen.nl/ah-franchise", "_self");
    }
    this.utilService.setTitle("AH Franchisers - Medewerkers aanmelden");
    this.isLoading = false;
    this.addStudentForm();
    this.addStudentForm();
    this.addStudentForm();
    this.addStudentForm();
    this.addStudentForm();
    this.studentForms[0].get("storeNr")?.valueChanges.subscribe((value) => {
      if (this.useStoreNrEverywhere) {
        for (const form of this.studentForms) {
          form.controls["storeNr"].setValue(value);
        }
      }
    });
  }

  async onStudentSignUpFormSubmit() {
    this.isLoading = true;
    this.registratorEmail = (this.authorizedRegistratorForm.get("email")?.value ?? "").toLowerCase();
    let index = 0;
    this.loadingMessage = index + " medewerkers uitgenodigd.";
    for (const form of this.studentForms) {
      if (form.valid) {
        this.schoolService
          .addStudent(this.school.id as number, {
            registrator_email: this.registratorEmail,
            email: (form.get("email")?.value ?? "").toLowerCase(),
            first_name: form.get("firstName")?.value,
            last_name: form.get("lastName")?.value,
            group: form.get("storeNr")?.value,
          })
          .subscribe();
        this.students.push({
          email: (form.get("email")?.value ?? "").toLowerCase(),
          first_name: form.get("firstName")?.value,
          last_name: form.get("lastName")?.value,
          group: form.get("storeNr")?.value,
        });
        index++;
        if (index === 1) {
          this.loadingMessage = index + " medewerker uitgenodigd.";
        } else {
          this.loadingMessage = index + " medewerkers uitgenodigd.";
        }
        await this.utilService.sleep(1500);
        form.disable();
      }
    }
    this.schoolService.authorizedRegistersVerfication(this.registratorEmail, this.students).subscribe({
      next: () => {
        this.loadingMessage += " Bevestingsmail verzonden.";
        this.isLoading = false;
      },
    });
  }

  onAuthorizedRegistratorFormSubmit() {
    this.isLoading = true;
    this.authorizedRegistersErrorMessage = "";
    this.schoolService.authorizedRegistersCheck((this.authorizedRegistratorForm.get("email")?.value ?? "").toLowerCase()).subscribe({
      next: (schools) => {
        if (schools.length > 0) {
          this.school = schools[0];
        }
        this.isAuthorized = true;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        if (error.status === 403) {
          this.authorizedRegistersErrorMessage = "E-mailadres niet herkend, neem contact op met info@onlineslagen.nl om je aan te melden.";
        } else {
          this.authorizedRegistersErrorMessage = "Er ging iets fout";
        }
      },
    });
  }

  onUseStoreNrEverywhere() {
    this.useStoreNrEverywhere = !this.useStoreNrEverywhere;
    if (this.useStoreNrEverywhere) {
      this.firstStoreNr = this.studentForms[0].get("storeNr")?.value;
      for (const form of this.studentForms) {
        form.controls["storeNr"].setValue(this.firstStoreNr);
      }
    }
  }

  addStudentForm() {
    this.studentForms.push(
      new FormGroup({
        firstName: new FormControl("", Validators.required),
        lastName: new FormControl("", Validators.required),
        email: new FormControl("", [Validators.required, Validators.email]),
        storeNr: new FormControl(this.useStoreNrEverywhere ? this.firstStoreNr! : "", Validators.required),
      })
    );
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
