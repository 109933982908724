import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatWidgetStoreService {
  private readonly excludedRoutes = [
    "/demo-docent",
    "/auth",
    "/winkel",
    "/boekenwinkel",
    "/examentrainingen",
    "/ah",
    "/ah-account-afmaken",
    "/ah-franchise",
    "/derooipannen",
    "/lidl",
    "/dominos",
    "/oude-examens/:old-exam-id/score-per-onderdeel-calculator",
    "/bijlage/:attachment-id",
    "/bestand/:file-id",
    "/gesprekken",
    "/contact",
  ];
  private readonly _isChatWidgetOpen = new BehaviorSubject<boolean>(false);
  private readonly _isChipButtonVisible = new BehaviorSubject<boolean>(false);

  readonly isChatWidgetOpen$ = this._isChatWidgetOpen.asObservable();
  readonly isChipButtonVisible$ = this._isChipButtonVisible.asObservable();

  public openWidget() {
    this._isChatWidgetOpen.next(true);
  }

  public closeWidget() {
    this._isChatWidgetOpen.next(false);
  }

  public updateChipButtonVisibility(currentRoute: string) {
    const isExcludedRoute = this.excludedRoutes.some((route) => this.matchesRoute(currentRoute, route));

    this._isChipButtonVisible.next(!isExcludedRoute);
    isExcludedRoute && this.closeWidget();
  }

  private matchesRoute(currentRoute: string, allowedRoute: string): boolean {
    if (allowedRoute.includes(":")) {
      const regexPattern = allowedRoute.replace(/:[^\/]+/g, "[^/]+");
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(currentRoute);
    }

    return currentRoute === allowedRoute || currentRoute.startsWith(allowedRoute + "/");
  }
}
