import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { UserService } from "@app/shared/services/user.service";
import { UtilService } from "@app/shared/services/util.service";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SchoolService } from "../../school.service";

@Component({
  selector: "app-dominos",
  templateUrl: "./dominos.component.html",
  styleUrls: ["./dominos.component.scss"],
})
export class DominosComponent implements OnInit, OnDestroy {
  studentSignUpForm = new FormGroup({
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(11)]),
  });

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;
  showLoginMessage!: boolean;

  userExtended!: UserExtended;

  user!: PreSignUpUser;

  showPassword!: boolean;
  workLocations: string[] = [
    "Amsterdam - Bos en Lommerweg",
    "Amsterdam - Buitenveldert (Zuidas)",
    "Amsterdam - Ceintuurbaan",
    "Amsterdam - Linnaeusstraat",
    "Amsterdam - Oostelijke Handelskade",
    "Amsterdam - Osdorp",
    "Amsterdam - Overtoom",
    "Amsterdam - Rijnstraat",
    "Amsterdam - Slotermeerlaan",
    "Amsterdam - Stadionplein",
    "Bodegraven",
    "Dieren",
    "Dinxperlo",
    "Duiven",
    "Emmen 1 - Station",
    "Emmen 2 - Balingerbrink",
    "Emmen 3 - Angelslo",
    "Groningen 1 -  Boterdiep",
    "Groningen 10- Woldring",
    "Groningen 2 - Paddepoel",
    "Groningen 3 - Paterswoldseweg",
    "Groningen 4 - Stoepemaheerd",
    "Groningen 5 - Poelestraat",
    "Groningen 6 - Verlengde Hereweg",
    "Groningen 7 - Floresstraat",
    "Groningen 8 - Lewenborg",
    "Hoogkarspel",
    "Hulst",
    "Leeuwarden 1",
    "Leeuwarden 2",
    "Leeuwarden 3",
    "Leiden Stationsweg",
    "Leiden Vijf Meilaan",
    "Pijnacker",
    "Ulft",
    "Waddinxveen",
    "Zierikzee",
  ];
  selectedWorkLocation: string | undefined = undefined;

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (UtilService.isSchool24()) {
      window.open("https://portal.onlineslagen.nl/dominos", "_self");
    }
    this.utilService.setTitle("Domino's - Aanmelden");
    this.isLoading = false;
    this.getParams();
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
  }
  getParams() {
    const email = this.route.snapshot.queryParams.email;
    const firstName = this.route.snapshot.queryParams.firstName;
    const lastName = this.route.snapshot.queryParams.lastName;

    if (email) {
      this.studentSignUpForm.get("email")?.setValue(email);
    }
    if (firstName) {
      this.studentSignUpForm.get("firstName")?.setValue(firstName);
    }
    if (lastName) {
      this.studentSignUpForm.get("lastName")?.setValue(lastName);
    }
  }

  onStudentSignUpFormSubmit() {
    if (this.studentSignUpForm.valid && this.selectedWorkLocation) {
      this.user = this.utilService.createPreSignUpUser(
        (this.studentSignUpForm.get("email")?.value ?? "").toLowerCase(),
        this.studentSignUpForm.get("password")?.value ?? "",
        this.studentSignUpForm.get("firstName")?.value ?? "",
        this.studentSignUpForm.get("lastName")?.value ?? "",
        "",
        this.selectedWorkLocation,
        this.router.url
      );
      this.setState("", "Account aanmaken...", true, false);
      this.enrollSchool();
    }
  }

  enrollSchool() {
    this.schoolService.enrollDominosSchool(this.user).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.setState("", "Account aanmaken...", true, false);
          this.loginUserPortal();
        } else {
          this.setState("Email is al in gebruik", "", false, false);
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.setState("Er ging iets fout. Neem contact op via de chat of info@onlineslagen.nl", "", false, false);
        } else {
          this.setState("Email is al in gebruik", "", false, false);
        }
      },
    });
  }

  loginUserPortal() {
    this.authService
      .learningPortalLogin(this.user)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (output_portal) => {
          this.cookieService.set("token", output_portal.key);
          this.getUser();
        },
        error: (error) => {
          this.handleLoginError(error);
        },
      });
  }

  getUser() {
    this.userService
      .getUserExtended()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userExtended) => {
        this.userExtended = userExtended;
        this.updateUser();
      });
  }

  updateUser() {
    this.userService.updateUserExtended(this.userExtended).subscribe(() => {
      this.setState("", "Ingelogd! Je wordt nu doorverwezen naar de trainingen", true, false);
      this.router.navigateByUrl("/home/vakken");
    });
  }

  onItemSelected(workLocation: string) {
    this.selectedWorkLocation = workLocation;
  }

  handleLoginError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.setState("", "Accountgegevens kloppen niet.", false, true);
    } else {
      this.handleError(error);
    }
  }

  onGoToLogin() {
    this.router.navigate(["auth", "login"], {
      queryParams: { email: this.studentSignUpForm.get("email")?.value },
    });
  }

  handleError(error: Error) {
    console.log(error);
    this.setState("", "Er ging iets onverwacht fout. Controleer je verbinding of neem contact met ons op.", false, true);
  }

  setState(errorMessage: string, loadingMessage: string, isLoading: boolean, showLoginMessage: boolean) {
    this.errorMessage = errorMessage;
    this.loadingMessage = loadingMessage;
    this.isLoading = isLoading;
    this.showLoginMessage = showLoginMessage;
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
