import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AsyncPipe } from "@angular/common";
import { ChatWidgetComponent } from "@app/ai-teacher/components/chat-widget/chat-widget.component";
import { ChatWidgetStoreService } from "@app/shared/stores/chat-widget-store.service";

@Component({
  selector: "chat-chip",
  standalone: true,
  templateUrl: "./chat-chip.component.html",
  styleUrl: "./chat-chip.component.scss",
  imports: [ChatWidgetComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatChipComponent {
  constructor(protected readonly chatWidgetStoreService: ChatWidgetStoreService) {}

  protected onShowWidget() {
    this.chatWidgetStoreService.openWidget();
  }

  protected onCloseWidget() {
    this.chatWidgetStoreService.closeWidget();
  }
}
