import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { interval } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { Router, NavigationStart } from "@angular/router";
import { Location, PopStateEvent } from "@angular/common";

import { UserExtended } from "./shared/models/entities/user/UserExtended";
import { UtilService } from "./shared/services/util.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private unsubscribe = new Subject();
  errorMessage!: string;
  title!: string;
  lastPoppedUrl: string | undefined = "";
  yScrollStack: number[] = [];
  scrollInterval = 100;

  checkUserTimer!: ReturnType<typeof setInterval>;
  userExtended!: UserExtended;

  constructor(
    private location: Location,
    private router: Router,
    private utilService: UtilService
  ) {
    this.scrollToLastPosition();
  }

  scrollToLastPosition() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        } else {
          this.lastPoppedUrl = undefined;
          const yposition = this.yScrollStack.pop() as number;
          let maxInterval = 0; // used to stop subscription
          interval(this.scrollInterval)
            .pipe(takeWhile(() => window.scrollY < yposition && maxInterval < 5000))
            .subscribe(() => {
              maxInterval += this.scrollInterval;
              window.scrollTo({
                top: yposition,
                left: 0,
              });
            });
        }
      }
    });
  }

  ngOnInit(): void {
    this.setTitle();
  }

  setTitle() {
    this.title = UtilService.getProductName() + " - Leeromgeving";
  }
}
