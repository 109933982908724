<div class="background-light-blue py-5">
  <div class="col-md-10 col-11 col-xl-5 col-xxl-4 px-0 mx-auto pb-md-5 my-0">
    <div class="d-flex flex-wrap justify-content-between pe-5 mb-4">
      <img
        src="../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen"
        style="max-height: 40px"
        class="me-md-5 me-0"
      />
      <img
        src="../../../../assets/partners/ah_logo.png"
        alt="Albert Heijn"
        style="max-height: 60px"
        class="ms-md-5 ms-0"
      />
    </div>

    <form
      [formGroup]="studentSignUpForm"
      class="col-md-10 col-12 mx-auto px-4"
    >
      <shared-sub-title
        [title]="'Maak je account compleet'"
        class="d-block mb-3"
      ></shared-sub-title>
      <div class="col-12 px-0 mb-3">
        <label class="d-block os-h8-arvo-bold">Personeelsnummer</label>
        <small class="d-block mb-2">
          <span
            class="os-link"
            (click)="onHowEmployeeNrClicked()"
          >
            Hier vind je je personeelsnummer.
          </span>
        </small>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'employeeID'"
          [placeHolder]="'Vul hier je personeelsnummer in...'"
          [errorMessage]="'Personeelsnummer moet precies 10 tekens zijn'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-3">
        <label class="d-block os-h8-arvo-bold mb-2">Winkelnummer (optioneel)</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'storeNr'"
          [placeHolder]="'Vul hier je winkelnummer in...'"
          [errorMessage]="'Winkelnummer moet precies 4 nummers zijn'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-5">
        <div>
          <label class="d-block os-h8-arvo-bold mb-2">Ik werk bij</label>
          <div class="d-flex">
            <div
              *ngFor="let workLocation of workLocations"
              class="answer-container os-bold font-14 cursor-pointer me-2"
              (click)="onWorkLocationClicked(workLocation)"
              [ngClass]="selectedWorkLocation === workLocation ? 'selected' : 'unselected'"
            >
              {{ workLocation }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <shared-button
          [text]="'Naar leeromgeving'"
          [isDisabled]="!studentSignUpForm.valid"
          [isLoading]="isLoading"
          (click)="onStudentSignUpFormSubmit()"
        ></shared-button>
      </div>
    </form>

    <ng-template #loading>
      <loading-spinner-small-white></loading-spinner-small-white>
    </ng-template>

    <div class="text-center mt-3">
      <div>{{ loadingMessage }}</div>
      <div class="text-danger">{{ errorMessage }}</div>
    </div>
  </div>
</div>
