// src/app/auth/token.interceptor.ts

import { Injectable } from "@angular/core";

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  auth_token!: string;
  excluded_urls: string[] = [
    "https://portal.onlineslagen.nl/api/login/",
    "https://portal.onlineslagen.nl/api/rest-auth/login/",
    "https://portal.school24.nl/api/login/",
    "https://portal.school24.nl/api/rest-auth/login/",
    "https://portal.school24.sr/api/login/",
    "https://portal.school24.sr/api/rest-auth/login/",
    "https://staging.onlineslagen.nl/api/login/",
    "https://staging.onlineslagen.nl/api/rest-auth/login/",
    "http://localhost:8000/api/login/",
    "http://localhost:8000/api/rest-auth/login/",
    "https://api.openai.com/v1/chat/completions",
    "https://api.openai.com/v1/images/generations",
    "/api/login/",
    "/api/rest-auth/login/",
  ];

  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth_token = this.cookieService.get("token");

    if (!this.excluded_urls.includes(request.url)) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Token " + this.auth_token),
      });
    }

    if (request.method === "GET") {
      request = request.clone({
        headers: request.headers
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache")
          .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
          .set("If-Modified-Since", "0"),
      });
    }

    request = request.clone({
      headers: request.headers.set("Api-Key", "NMktRSNa8fXe3EeipLtt3LFxMqKT"),
    });

    return next.handle(request);
  }
}
