@if (chatWidgetStoreService.isChatWidgetOpen$ | async) {
  <chat-widget (closeWidget)="onCloseWidget()"></chat-widget>
} @else {
  <div
    class="position-fixed cursor-pointer user-select-none chat-widget-button"
    (click)="onShowWidget()"
  >
    <img src="assets/button_icons/chat.png" />
  </div>
}
