<div class="background-light-blue py-5">
  <div class="col-md-10 col-11 col-xl-4 px-0 mx-auto py-md-5 my-0">
    <div class="d-flex flex-wrap">
      <img
        src="../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen"
        style="max-height: 40px"
        class="me-md-5 me-0"
      />
      <img
        src="../../../../assets/partners/de_rooie_pannen.png"
        alt="De Rooi Pannen"
        style="max-height: 60px"
        class="ms-md-5 ms-0"
      />
    </div>

    <h1 class="font-arvo os-bold mt-5 mb-3">Welkom bij OnlineSlagen</h1>

    <p class="pb-2">
      De Rooi Pannen biedt haar leerlingen alle examentrainingen van OnlineSlagen zodat zij zich optimaal kunnen voorbereiden op de
      eindexamens. Via deze pagina kan je je aanmelden zodra je de aanmeldcode hebt ontvangen!
    </p>

    <div class="pb-4">
      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Geanimeerde
          <b>uitlegvideo’s</b>
          en duidelijke
          <b>samenvattingen</b>
          (CE + SE stof)
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Uitgebreide
          <b>examenbundel</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Oefenomgeving voor alle
          <b>begrippen</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Duizenden extra
          <b>oefenopgaven</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          11 uur per dag
          <b>online bijles</b>
          via de chat (vanaf 1 april)
        </div>
      </div>
    </div>

    <form
      [formGroup]="studentSignUpForm"
      class="col-md-10 col-12 mx-auto px-4"
      (ngSubmit)="onStudentSignUpFormSubmit()"
    >
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold col-12 px-0">Voornaam</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'firstName'"
          [placeHolder]="'Vul hier je voornaam in...'"
          [errorMessage]="'Voornaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Achternaam</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'lastName'"
          [placeHolder]="'Vul hier je achternaam in...'"
          [errorMessage]="'Achternaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">E-mail</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'email'"
          [type]="'email'"
          [placeHolder]="'Vul hier je e-mail in...'"
          [errorMessage]="'Vul een geldig e-mailadres in'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Wachtwoord</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'password'"
          [type]="'password'"
          [placeHolder]="'Vul hier je wachtwoord in...'"
          [errorMessage]="'Wachtwoord moet minstens 11 tekens zijn'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Aanmeldcode</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'inviteCode'"
          [placeHolder]="'Vul hier je aanmeldcode in...'"
          [errorMessage]="'Aanmeldcode is verplicht'"
        ></shared-input>
      </div>
      <shared-button
        class="d-block text-center"
        [text]="'Aanmaken'"
        [isDisabled]="!studentSignUpForm.valid"
        [isLoading]="isLoading"
        (click)="onStudentSignUpFormSubmit()"
      ></shared-button>
      <div class="mt-3 text-center">
        <p>
          Account al aangemaakt?
          <a href="/auth">Klik hier om in te loggen</a>
        </p>
      </div>
    </form>

    <ng-template #loading>
      <loading-spinner-small-white></loading-spinner-small-white>
    </ng-template>

    <div class="text-center mt-3">
      <div>{{ loadingMessage }}</div>
      <div class="text-danger">{{ errorMessage }}</div>
    </div>
  </div>
</div>

<anonymous-chat-component></anonymous-chat-component>
