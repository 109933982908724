<div class="background-light-blue pt-5 scroll-padding">
  <div class="col-md-10 col-11 col-xl-5 col-xxl-4 px-0 mx-auto py-md-5 my-0">
    <div class="d-flex flex-wrap justify-content-between pe-5">
      <img
        src="../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen"
        style="max-height: 40px"
        class="me-md-5 me-0"
      />
      <img
        src="../../../../assets/partners/dominos.png"
        alt="Dominos"
        style="max-height: 48px"
        class="ms-md-5 ms-0"
      />
    </div>

    <h1 class="font-arvo os-bold mt-5 mb-3">Welkom bij OnlineSlagen</h1>

    <p class="pb-2">
      Doe jij dit jaar eindexamen? Of zit jij in de bovenbouw? Bij Domino's vinden we een diploma net zo belangrijk als werkervaring. Daarom
      krijg jij met het alles-in-één examenpakket van OnlineSlagen alles wat je nodig hebt om dat diploma te halen!
    </p>
    <div class="pb-4">
      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Geanimeerde
          <b>uitlegvideo’s</b>
          en duidelijke
          <b>samenvattingen</b>
          (CE + SE stof)
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Uitgebreide
          <b>examenbundel</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Oefenomgeving voor alle
          <b>begrippen</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          Duizenden extra
          <b>oefenopgaven</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="ms-2">
          11 uur per dag
          <b>online bijles</b>
          via de chat (vanaf 1 april)
        </div>
      </div>
    </div>

    <form
      [formGroup]="studentSignUpForm"
      class="mx-auto px-4"
    >
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold col-12 px-0">Voornaam</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'firstName'"
          [placeHolder]="'Typ voornaam...'"
          [errorMessage]="'Voornaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Achternaam</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'lastName'"
          [placeHolder]="'Typ achternaam...'"
          [errorMessage]="'Achternaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">E-mail</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'email'"
          [type]="'email'"
          [placeHolder]="'Typ e-mail...'"
          [errorMessage]="'Vul een geldig e-mailadres in'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Wachtwoord</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'password'"
          [type]="'password'"
          [placeHolder]="'Typ wachtwoord...'"
          [errorMessage]="'Wachtwoord moet minstens 11 tekens zijn'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <shared-selector
          [list]="workLocations"
          [placeHolder]="'Kies je winkel'"
          (itemSelect)="onItemSelected($event)"
        ></shared-selector>
      </div>
      <div class="text-center">
        <shared-button
          class="mt-3"
          [isDisabled]="!studentSignUpForm.valid || !selectedWorkLocation"
          [isLoading]="isLoading"
          [text]="'Aanmaken'"
          (click)="onStudentSignUpFormSubmit()"
        ></shared-button>
      </div>
      <div class="text-center mt-3">
        <div>{{ loadingMessage }}</div>
        <div *ngIf="errorMessage">
          <div class="text-danger">{{ errorMessage }}</div>
          <div
            class="os-link"
            (click)="onGoToLogin()"
          >
            Klik hier om in te loggen
          </div>
        </div>
      </div>
    </form>

    <ng-template #loading>
      <loading-spinner-small-white></loading-spinner-small-white>
    </ng-template>
  </div>
</div>
