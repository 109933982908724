import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { UserService } from "@app/shared/services/user.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AuthGuardAdminService {
  constructor(
    public router: Router,
    public authService: AuthService,
    private userService: UserService
  ) {}

  canActivate(): Observable<boolean> {
    return this.userService.getUserExtended().pipe(
      map((user: UserExtended) => {
        if (user.user.is_staff === true) {
          return true;
        }
        this.router.navigate(["no-admin-access"]);
        return false;
      }),
      catchError(() => {
        this.router.navigate(["auth"]);
        return of(false);
      })
    );
  }
}
