import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { UtilService } from "@app/shared/services/util.service";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SchoolPublicData } from "../../models/SchoolPublicData";
import { TeacherInviteCode } from "../../models/TeacherInviteCode";
import { SchoolService } from "../../school.service";

@Component({
  selector: "app-lidl",
  templateUrl: "./lidl.component.html",
  styleUrls: ["./lidl.component.scss"],
})
export class LidlComponent implements OnInit, OnDestroy {
  studentSignUpForm = new FormGroup({
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(11)]),
    inviteCode: new FormControl("", Validators.required),
    controlAnswer2: new FormControl("", Validators.required),
  });

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;
  showLoginMessage!: boolean;
  registerLoginTitle!: string;

  schoolShortCode!: string;

  userExtended!: UserExtended;
  school!: SchoolPublicData;
  teacherInviteCode!: TeacherInviteCode;

  user!: PreSignUpUser;

  userTypes!: string[];
  actions!: string[];
  selectedUserType!: string;
  selectedAction!: string;
  showPassword!: boolean;

  regions!: string[];
  selectedRegio!: string;
  productOptions: string[] = ["Examentraining", "Huiswerkbegeleiding"];
  selectedProductOptions: string[] = [];

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    if (UtilService.isSchool24()) {
      window.open("https://portal.onlineslagen.nl/lidl", "_self");
    }
    this.utilService.setTitle("Lidl - Aanmelden");
    this.isLoading = false;
    this.regions = ["Heerenveen", "Etten-Leur", "Oosterhout", "Almere", "Weert", "Waddinxveen", "Tiel", "HQ"];
  }

  onStudentSignUpFormSubmit() {
    if (this.studentSignUpForm.valid && this.selectedProductOptions?.length > 0 && this.selectedRegio && !this.isLoading) {
      this.user = this.utilService.createPreSignUpUser(
        (this.studentSignUpForm.get("email")?.value as string).toLowerCase(),
        this.studentSignUpForm.get("password")?.value as string,
        this.studentSignUpForm.get("firstName")?.value as string,
        this.studentSignUpForm.get("lastName")?.value as string,
        this.studentSignUpForm.get("inviteCode")?.value as string,
        this.selectedProductOptions.toString(),
        this.selectedRegio + " " + this.studentSignUpForm.get("controlAnswer2")?.value
      );
      this.studentSignUpForm.disable();
      this.setState("", "Voucher code controleren", true, false);
      this.activateInviteCode();
    }
  }

  activateInviteCode() {
    this.schoolService
      .activateInviteCode(this.user, this.user.inviteCode as string)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          if (response.status === 201) {
            this.setState("", "Code geldig! Account aanmaken...", true, false);
            this.loginUserPortal();
          } else {
            this.setState("Email is al in gebruik", "", false, false);
            this.studentSignUpForm.enable();
          }
        },
        error: (error) => {
          this.handleActivateSchoolInviteCodeError(error);
          this.studentSignUpForm.enable();
        },
      });
  }

  loginUserPortal() {
    this.authService
      .learningPortalLogin(this.user)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (output_portal) => {
          this.cookieService.set("token", output_portal.key);
          this.setState("", "Ingelogd! Je wordt nu doorverwezen naar de trainingen", true, false);
          this.router.navigateByUrl("/home/vakken");
        },
        error: (error) => {
          this.handleLoginError(error);
        },
      });
  }

  onRegioSelected(regio: string) {
    this.selectedRegio = regio;
  }

  onProductOptionClicked(productOption: string) {
    const index = this.selectedProductOptions.indexOf(productOption);
    if (index > -1) {
      this.selectedProductOptions.splice(index, 1);
    } else {
      this.selectedProductOptions.push(productOption);
    }
  }

  handleActivateSchoolInviteCodeError(error: HttpErrorResponse) {
    if (error.status == 404) {
      this.setState("Voucher code ongeldig.", "", false, true);
    } else if (error.status == 401) {
      this.setState("Deze code is helaas al gebruikt.", "", false, true);
    } else if (error.status == 403) {
      this.setState("Emailadres is al gebruikt.", "", false, true);
    } else {
      this.handleError();
    }
  }

  handleLoginError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.setState("", "Accountgegevens kloppen niet.", false, true);
    } else {
      this.handleError();
    }
  }

  handleError() {
    this.setState("", "Er ging iets onverwacht fout. Controleer je verbinding of neem contact met ons op.", false, true);
  }

  setState(errorMessage: string, loadingMessage: string, isLoading: boolean, showLoginMessage: boolean) {
    this.errorMessage = errorMessage;
    this.loadingMessage = loadingMessage;
    this.isLoading = isLoading;
    this.showLoginMessage = showLoginMessage;
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
