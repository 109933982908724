import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { DatePipe, registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";

import { CookieService } from "ngx-cookie-service";
import { UserChatGroupService } from "./shared/services/user-chat-group.service";
import { AuthGuardService } from "./auth/auth-guard.service";

import { AppComponent } from "./app.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AuthHeaderInterceptor } from "./shared/interceptors/auth-header.interceptor";
import { AuthGuardAdminService } from "./auth/auth-guard-admin.service";
import { AuthGuardTeacherService } from "./auth/auth-guard-teacher.service";

import { AhComponent } from "./school/partners/ah/ah.component";
import { DominosComponent } from "./school/partners/dominos/dominos.component";
import { DeRooiPannenComponent } from "./school/partners/derooipannen/derooipannen.component";
import { LidlComponent } from "./school/partners/lidl/lidl.component";
import { FranchisersComponent } from "./school/partners/franchisers/franchisers.component";
import { AhCompleteAccountComponent } from "./school/partners/ah-complete-account/ah-complete-account.component";
import { EmployeeNrModalComponent } from "./school/partners/ah-complete-account/components/employee-nr-modal.component";
import {
  SubTitleComponent,
  SelectorComponent,
  TitleComponent,
  ErrorMessageComponent,
  InputComponent,
  ButtonComponent,
  LoadingSpinnerSmallWhiteComponent,
} from "@app/shared/basic-components";
import { ErrorComponent } from "@app/shared/feature-components/error";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ExamQRLandingComponent } from "./training/training-page/onlineslagen/components/exams/exam-qr-landing/exam-qr-landing.component";
import { ChatChipComponent } from "@app/ai-teacher/components/chat-chip/chat-chip.component";
import { AnonymousChatComponent, ChatComponent } from "./shared/feature-components/chat";

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    DeRooiPannenComponent,
    AhComponent,
    AhCompleteAccountComponent,
    EmployeeNrModalComponent,
    LidlComponent,
    DominosComponent,
    FranchisersComponent,
    ExamQRLandingComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    TitleComponent,
    ErrorComponent,
    SubTitleComponent,
    SelectorComponent,
    ErrorMessageComponent,
    InputComponent,
    ButtonComponent,
    LoadingSpinnerSmallWhiteComponent,
    ChatChipComponent,
    ChatComponent,
    AnonymousChatComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "nl" },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    UserChatGroupService,
    AuthGuardService,
    AuthGuardTeacherService,
    AuthGuardAdminService,
    DatePipe,
    MatSnackBar,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
