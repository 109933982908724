<div class="background-light-blue py-5">
  <div class="col-md-10 col-11 col-xl-5 col-xxl-4 px-0 mx-auto py-md-3 my-0">
    <div class="flex-between">
      <img
        src="../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen"
        style="max-height: 40px"
      />
      <img
        src="../../../../assets/partners/lidl.png"
        alt="Lidl"
        style="max-height: 60px"
      />
    </div>

    <h1 class="fon8-jost os-bold my-3">Welkom bij OnlineSlagen</h1>

    <p class="">
      Lidl geeft haar werknemers online examentrainingen en online huiswerkbegeleiding, zodat zij zich optimaal kunnen voorbereiden op hun
      examens en toetsen. Via deze pagina kun je je aanmelden en direct aan de slag!
    </p>

    <h4 class="mt-4 mb-3 os-bold font-22">Online Examentrainingen (voor eindexamenkandidaten)</h4>
    <div class="pb-3">
      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>Video’s</b>
          over de gehele examenstof
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>Samenvattingen</b>
          over alle stof, inclusief printbaar boek
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>Begrippenlijsten</b>
          met een handige oefentool
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>Examenbundel</b>
          inclusief oefenen per onderdeel
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>1000+ opgaven</b>
          met stap voor stap uitleg
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">
          <b>Inzicht</b>
          in je sterke en zwakke onderwerpen
        </div>
      </div>
    </div>
    <h4 class="mb-3 os-bold font-22">Online Huiswerkbegeleiding (voor leerlingen uit alle leerjaren)</h4>
    <div class="pb-5">
      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">Stel al je vragen via de online bijles chat</div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">Iedere dag van 15:00 t/m 21:00, vanaf 1 April van 10:00 t/m 21:00</div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class="font-16 os-semi-bold ms-2">Video’s, samenvattingen en oefeningen over al jouw lesstof</div>
      </div>
    </div>
    <form
      [formGroup]="studentSignUpForm"
      class="mx-auto"
      (ngSubmit)="onStudentSignUpFormSubmit()"
    >
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">Voornaam</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'firstName'"
          [placeHolder]="'Vul hier je voornaam in...'"
          [errorMessage]="'Voornaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">Achternaam</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'lastName'"
          [placeHolder]="'Vul hier je achternaam in...'"
          [errorMessage]="'Achternaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18">E-mail</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'email'"
          [type]="'email'"
          [placeHolder]="'Vul hier je e-mail in...'"
          [errorMessage]="'Vul een geldig e-mailadres in'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18">Wachtwoord</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'password'"
          [type]="'password'"
          [placeHolder]="'Vul hier je wachtwoord in...'"
          [errorMessage]="'Wachtwoord moet minstens 11 tekens zijn'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">Vouchercode</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'inviteCode'"
          [placeHolder]="'Vul hier je vouchercode in...'"
          [errorMessage]="'Vouchercode is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">Regio</label>
        <shared-selector
          [list]="regions"
          [placeHolder]="'Kies je regio'"
          (itemSelect)="onRegioSelected($event)"
        ></shared-selector>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">In welk filiaal of welke afdeling werk je?</label>
        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'controlAnswer2'"
          [placeHolder]="'Vul filiaal of afdeling in...'"
          [errorMessage]="'Dit veld is verplicht'"
        ></shared-input>
      </div>

      <div class="col-12 px-0 mb-4">
        <label class="os-bold font-18 col-12 px-0">Examentraining en/of huiswerkbegeleiding?</label>
        <span
          *ngFor="let productOption of productOptions"
          class="d-inline-block answer-container os-bold font-14 cursor-pointer me-2"
          (click)="onProductOptionClicked(productOption)"
          [ngClass]="selectedProductOptions.indexOf(productOption) > -1 ? 'selected' : 'unselected'"
        >
          {{ productOption }}
        </span>
      </div>
      <div>
        <p class="os-bold font-10">
          Door een account aan te maken ga ik akkoord met de
          <a
            href="https://www.onlineslagen.nl/algemene-voorwaarden/"
            target="_blank"
          >
            algemene voorwaarden
          </a>
          en het
          <a
            href="https://www.onlineslagen.nl/privacybeleid/"
            target="_blank"
          >
            privacybeleid
          </a>
          van OnlineSlagen B.V.
        </p>
      </div>
      <shared-button
        class="d-block text-center"
        [text]="'Aanmaken'"
        [isDisabled]="!studentSignUpForm.valid || !selectedRegio || selectedProductOptions?.length === 0"
        [isLoading]="isLoading"
        (click)="onStudentSignUpFormSubmit()"
      ></shared-button>
      <div class="text-center mt-3">
        <div>{{ loadingMessage }}</div>
        <div *ngIf="errorMessage">
          <div class="text-danger">{{ errorMessage }}</div>
          <div
            class="os-link"
            (click)="onGoToLogin()"
          >
            Klik hier om in te loggen
          </div>
        </div>
      </div>
    </form>

    <ng-template #loading>
      <loading-spinner-small-white></loading-spinner-small-white>
    </ng-template>
  </div>
</div>

<anonymous-chat-component></anonymous-chat-component>
