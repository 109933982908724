import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { UserService } from "@app/shared/services/user.service";
import { UtilService } from "@app/shared/services/util.service";
import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class NewUserGuard {
  constructor(
    public router: Router,
    public userService: UserService
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.userService.getUserExtended().pipe(map((user) => (!user.new_user ? true : this.navigateToOnboardingPage(user))));
  }

  protected navigateToOnboardingPage(user: UserExtended): UrlTree {
    if ((user.role === OSRoles.Teacher || user.role === OSRoles.DemoTeacher) && !UtilService.isSchool24()) {
      return this.buildUrlTree([`/scholen/welkom`]);
    } else if ((user.s24_role === S24Roles.Teacher || user.s24_role === S24Roles.DemoTeacher) && UtilService.isSchool24()) {
      this.updateUser(user);
      return this.buildUrlTree([`/home/vakken`]);
    } else {
      return this.buildUrlTree([`/onboarding`]);
    }
  }

  private updateUser(user: UserExtended) {
    user.new_user = false;
    this.userService.updateUserExtended(user).subscribe();
  }

  private buildUrlTree(fragments: string[]): UrlTree {
    return this.router.createUrlTree(fragments);
  }
}
