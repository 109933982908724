<div class="os-page-background scroll-padding pt-3 pt-xl-4">
  <div
    *ngIf="!isAuthenticated && !error"
    class="text-center"
    style="margin-top: 160px"
  >
    <img
      src="../../../../assets/brand/os/logo/dark.svg"
      alt="OnlineSlagen"
      style="height: 48px"
      class="mb-4"
    />
    <shared-title
      [title]="'Welkom bij OnlineSlagen'"
      class="d-block mb-3"
    ></shared-title>
    <div class="d-none d-xl-block font-22 mb-4">Login of maak een account. Daarna kun je jouw score per onderdeel berekenen.</div>
    <div class="d-block d-xl-none font-16 mb-4">Login of maak een account. Daarna kun je jouw score per onderdeel berekenen.</div>
    <div>
      <shared-button
        [text]="'Inloggen'"
        [style]="'secondary'"
        (click)="onLoginClicked()"
        class="me-3"
      ></shared-button>
      <shared-button
        [text]="'Account aanmaken'"
        (click)="onRegisterClicked()"
      ></shared-button>
    </div>
  </div>

  <div
    *ngIf="isAuthenticated && !error"
    class="col-12 col-xl-4 mx-auto os-block-3 p-4 pt-3"
    style="margin-top: 160px"
  >
    <div
      *ngIf="examAttempt"
      class="os-text-lightgrey font-22"
    >
      {{ examAttempt?.training.training_name }}
      <span class="capitalize-first-letter">{{ TrainingLevelEnum[examAttempt?.training.training_level] }}</span>
      - {{ title }}
    </div>
    <shared-title
      [title]="'Bereken je cijfer'"
      class="d-block mb-3"
    ></shared-title>
    <p>Je ziet dadelijk hetzelfde examen als in het boekje, maar dan online. Vul hier jouw scores per opgave in.</p>
    <p>
      Je ziet dan welk cijfer jij zou hebben gehaald voor dit examen! Verder zie je jouw zwakke en sterke onderwerpen. Dan kun je gericht
      verder leren.
    </p>
    <div class="d-flex justify-content-end">
      <shared-button
        [text]="'Scores invullen'"
        [isLoading]="isLoading"
        (click)="onContinueClicked()"
      ></shared-button>
    </div>
  </div>

  <div *ngIf="error">
    <div
      *ngIf="error.status === 404"
      class="col-12 col-xl-4 mx-auto text-center os-block-3 p-4 pt-3"
      style="margin-top: 160px"
    >
      <shared-title
        [title]="'Examen bestaat niet'"
        class="d-block mb-1"
      ></shared-title>
      <div class="d-none d-xl-block font-22 mb-4">
        Helaas bestaat dit examen niet. Login of maak een account aan en oefen onbeperkt examens!
      </div>
      <div class="d-block d-xl-none font-16 mb-4">
        Helaas bestaat dit examen niet. Login of maak een account aan en oefen onbeperkt examens!
      </div>
      <div>
        <shared-button
          [text]="'Inloggen'"
          [style]="'secondary'"
          (click)="onLoginClicked()"
          class="me-3"
        ></shared-button>
        <shared-button
          [text]="'Account aanmaken'"
          (click)="onRegisterClicked()"
        ></shared-button>
      </div>
    </div>
    <error
      *ngIf="error.status !== 404"
      [errorResponse]="error"
    ></error>
  </div>
</div>
