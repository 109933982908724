<div class="background-light-blue pt-5 px-2 scroll-padding">
  <div class="col-12 col-md-10 col-xl-5 col-xxl-4 px-0 mx-auto pb-md-3 my-0">
    <div class="d-flex flex-wrap justify-content-between pe-0 pe-xl-5">
      <img
        src="../../../../assets/brand/os/logo/dark.svg"
        alt="OnlineSlagen"
        style="max-height: 40px"
        class="me-md-5 me-0"
      />
      <img
        src="../../../../assets/partners/etos.png"
        alt="Albert Heijn"
        style="max-height: 60px"
        class="ms-md-5 ms-0"
      />
      <img
        src="../../../../assets/partners/ah_logo.png"
        alt="Albert Heijn"
        style="max-height: 60px"
        class="ms-md-5 ms-0"
      />
    </div>

    <h1 class="col-11 col-md-12 font-arvo os-bold mt-4 mb-3 text-center">Gratis examentraining</h1>
    <iframe
      class="mb-3"
      frameborder="0"
      scrolling="no"
      style="width: 100%; aspect-ratio: 16 / 9"
      type="text/html"
      src="https://www.youtube.com/embed/hvnJ7cLZ8aA?autoplay=0&fs=0&iv_load_policy=1&showinfo=1&rel=1&cc_load_policy=1&start=0&end=0&origin=https://youtubeembedcode.com"
    ></iframe>

    <p class="text-center os-semi-bold mb-3">
      Doe jij dit jaar eindexamen? Of zit jij in de bovenbouw? Bij Albert Heijn vinden we een diploma net zo belangrijk als werkervaring.
      Daarom krijg jij de online examentraining van OnlineSlagen. Video's, samenvattingen, examenbundel en nog veel meer!
    </p>
    <p class="os-h7-arvo-bold text-center mb-3 mb-md-0">Meld je hieronder aan</p>
  </div>

  <!-- <p class=" pb-2">
       alles wat je nodig hebt
      om dat diploma te halen!
    </p>
    <div class="pb-4">
      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class=" ms-2">
          Geanimeerde <b>uitlegvideo’s</b> en duidelijke
          <b>samenvattingen</b> (CE + SE stof)
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class=" ms-2">Uitgebreide <b>examenbundel</b></div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class=" ms-2">
          Oefenomgeving voor alle <b>begrippen</b>
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class=" ms-2">Duizenden extra <b>oefenopgaven</b></div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <i class="fa-regular fa-circle-check os-text-blue me-1 font-20"></i>
        <div class=" ms-2">
          11 uur per dag <b>online bijles</b> via de chat (vanaf 1 april)
        </div>
      </div>
    </div> -->

  <div>
    <form
      [formGroup]="studentSignUpForm"
      class="col-12 col-md-6 col-xl-4 col-xxl-3 mx-auto os-block-3"
    >
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold col-12 px-0">Voornaam</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'firstName'"
          [placeHolder]="'Typ voornaam...'"
          [errorMessage]="'Voornaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Achternaam</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'lastName'"
          [placeHolder]="'Typ achternaam...'"
          [errorMessage]="'Achternaam is verplicht'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">E-mail</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'email'"
          [type]="'email'"
          [placeHolder]="'Typ e-mail...'"
          [errorMessage]="'Vul een geldig e-mailadres in'"
        ></shared-input>
      </div>
      <div class="col-12 px-0 mb-4">
        <label class="os-h8-arvo-bold">Wachtwoord</label>

        <shared-input
          [group]="studentSignUpForm"
          [controlName]="'password'"
          [type]="'password'"
          [placeHolder]="'Typ wachtwoord...'"
          [errorMessage]="'Wachtwoord moet minstens 11 tekens zijn'"
        ></shared-input>
      </div>
      <div class="text-center">
        <shared-button
          [text]="'Account aanmaken'"
          [isDisabled]="!studentSignUpForm.valid"
          [isLoading]="isLoading"
          (click)="onStudentSignUpFormSubmit()"
        ></shared-button>
      </div>
      <div class="text-center mt-3">
        <div>{{ loadingMessage }}</div>
        <div *ngIf="errorMessage">
          <div class="text-danger">{{ errorMessage }}</div>
          <div
            class="os-link"
            (click)="onGoToLogin()"
          >
            Klik hier om in te loggen
          </div>
        </div>
      </div>
    </form>

    <ng-template #loading>
      <loading-spinner-small-white></loading-spinner-small-white>
    </ng-template>
  </div>
</div>
