import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import katex from "katex";

if (environment.production) {
  enableProdMode();
}

// Make KaTeX globally available for Quill
(window as any).katex = katex;

platformBrowserDynamic().bootstrapModule(AppModule);
/*
document.addEventListener('deviceready', function() {
	platformBrowserDynamic().bootstrapModule(AppModule);
}, true);
*/
